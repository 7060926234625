import React from "react"

const FluidHeadline = ({
  headline,
  headlineLevel = "03",
  tagName = "h2",
  className,
  ...props
}) => {
  const HTag = tagName
  return (
    <HTag
      className={`headline fluid-headline-${headlineLevel} relative inline-block ${
        className ? className : ""
      }`}
      {...props}
      dangerouslySetInnerHTML={{
        __html: headline.replace("|", "<br />"),
      }}
    />
  )
}
export default FluidHeadline
