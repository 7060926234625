import React, { useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/sharp-light-svg-icons"

const BreadcrumbsMobileDropdown = ({ breadcrumbs }) => {
  const [showDropdownState, setShowDropdownState] = useState(false)
  return (
    <section className="border-b border-ro-gray-100">
      <div className="flex items-center h-12 block-w-container md:h-16">
        <div className="flex flex-row items-center w-full">
          {breadcrumbs?.map((breadcrumb, index) => (
            <span
              key={index}
              className={`flex ${
                index === breadcrumbs.length - 1 ? "min-w-0" : ""
              }`}
            >
              {/* Seperator: Rendered before every link except the frist one + only the first and last seperator are shown mobile */}
              {index > 0 && (
                <span
                  className={`w-6 h-6 mt-px -mb-px mx-0.5 justify-center items-center ${
                    index > 1 && index < breadcrumbs.length - 1
                      ? "hidden md:flex"
                      : "flex"
                  }`}
                >
                  <FontAwesomeIcon icon={faChevronRight} size="1x" />
                </span>
              )}
              {/* Dropdown: Rendered for the second breadcrumb and only shows mobile + has the links that show up regulary on mobile*/}
              {index === 1 && breadcrumbs.length > 2 && (
                <span className="relative md:hidden">
                  <button
                    className="focus-visible:outline-1 focus-visible:outline-offset-0 hover:text-ro-red-700 underline-offset-4 hover:underline text-ro-red whitespace-nowrap"
                    onClick={() =>
                      setShowDropdownState(showDropdownState ? false : true)
                    }
                  >
                    . . .
                  </button>
                  <div
                    className={`absolute z-10 mt-2 left-1 top-full ${
                      showDropdownState ? "block" : "hidden"
                    }`}
                  >
                    <div className="relative flex flex-col ro-search-box-shadow bg-ro-gray-50 text-ro-gray-600">
                      {breadcrumbs?.map(
                        (breadcrumb, index) =>
                          index > 0 &&
                          index < breadcrumbs.length - 1 && (
                            <Link
                              key={index}
                              to={breadcrumb.link}
                              className="block px-4 py-3 w-max min-w-40 hover:bg-ro-gray-75"
                            >
                              {breadcrumb.link_text}
                            </Link>
                          )
                      )}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 12 6"
                        fill="currentColor"
                        className="absolute w-3 bottom-full left-1 text-ro-gray-50"
                      >
                        <title>Down</title>
                        <path d="M0 6 L12 6 L6 0" />
                      </svg>
                    </div>
                  </div>
                </span>
              )}
              {/* Regular links: Only showing the first and last one on mobile */}
              <Link
                className={`focus-visible:outline-1 focus-visible:outline-offset-0 hover:text-ro-red-700 underline-offset-4 hover:underline text-ro-red whitespace-nowrap ${
                  index > 0 && index < breadcrumbs.length - 1
                    ? "hidden md:block"
                    : "block"
                } ${
                  index === breadcrumbs.length - 1
                    ? "overflow-hidden overflow-ellipsis"
                    : ""
                }`}
                key={index}
                to={breadcrumb.link}
              >
                {breadcrumb.link_text}
              </Link>
            </span>
          ))}
        </div>
      </div>
    </section>
  )
}

export default BreadcrumbsMobileDropdown
