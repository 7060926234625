import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import FluidHeadline from "../../../../components/atoms/FluidHeadline"
import BreadcrumbsMobileDropdown from "../../../../components/modules/BreadcrumbsMobileDropdown"

const HeroBlockMedia = ({ block }) => {
  const mediaHeightKey = block.media_height || "xl"

  const mediaHeightMap = {
    sm: "md:h-[320px]",
    md: "md:h-[480px]",
    lg: "md:h-[560px]",
    xl: "md:h-[640px]",
  }

  if (block.video?.filename) {
    return (
      <video
        className={`object-cover object-center w-full h-[240px] ${mediaHeightMap[mediaHeightKey]}`}
        autoPlay={true}
        muted={true}
        loop={true}
        src={block.video?.filename}
      />
    )
  } else if (block.image?.filename) {
    return (
      <>
        <Image
          image={block.image}
          title={block.title}
          alt={block.alt}
          className={`hidden object-cover object-center w-full md:block ${mediaHeightMap[mediaHeightKey]}`}
          adaptToPixelRatio={true}
        />
        <Image
          image={block.image}
          title={block.title}
          alt={block.alt}
          className="block object-cover w-full h-[240px] md:hidden"
          adaptToPixelRatio={true}
        />
      </>
    )
  } else {
    return <></>
  }
}

const HeroBlock = ({ block, breadcrumbs }) => {
  /*
  Bildhöhe
  klein = sm
  mittel = md
  groß = lg
  extragroß = xl
  */

  return (
    <>
      <BlockWrapper
        block={block}
        blockPadding=""
        blockWidth="screen"
        showHeadline="false"
      >
        <div className="w-full overflow-y-hidden">
          <HeroBlockMedia block={block} />
        </div>
        <div className="bg-ro-red text-ro-white">
          <div className="py-10 mx-auto block-w-container md:py-14">
            <div className="flex flex-row flex-wrap -mx-grid">
              <div className="w-full lg:w-1/2 px-grid">
                <FluidHeadline
                  headline={block.headline}
                  tagName="h1"
                  headlineLevel="04"
                />
              </div>
              {(block.text?.length > 0 || block.buttons?.length > 0) && (
                <div className="w-full mt-4 lg:w-1/2 px-grid lg:mt-0">
                  <Richtext
                    text={block.text}
                    className="mb-6 lg:mb-10 text-ro-white last:mb-0 lg:last:mb-0"
                  />
                  {block.buttons?.length > 0 && (
                    <div className="-mb-4 ">
                      {block.buttons.map((button, index) => (
                        <Button
                          key={index}
                          className={`headerbutton-ga4 flex w-full md:inline-block md:w-fit mb-4 ${
                            index + 1 < block.buttons.length ? "mr-4" : ""
                          }`}
                          btnType={
                            index === 0 ? "primaryWhite" : "outlineWhite"
                          }
                          link={button.link}
                          target={button.target}
                        >
                          {button.link_text}
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {breadcrumbs?.length > 0 && (
          <BreadcrumbsMobileDropdown breadcrumbs={breadcrumbs} />
        )}
      </BlockWrapper>
    </>
  )
}

export default HeroBlock
